import React from "react";
import { Button, FormControl, FormLabel, Input, VStack } from "@chakra-ui/react";

const Login = ({username, password, loading, handleLogin, setUsername, setPassword}) => {
  return (
    <VStack
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        handleLogin();
      }}
      spacing={4}
      w="full"
      maxWidth="600px"
    >
      <FormControl id="username" isRequired>
        <FormLabel>Username</FormLabel>
        <Input value={username} onChange={(e) => setUsername(e.target.value)} />
      </FormControl>
      <FormControl id="password" isRequired>
        <FormLabel>Password</FormLabel>
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
      </FormControl>
      <Button isLoading={loading} type="submit" colorScheme="blue">
        Login
      </Button>
    </VStack>
  );
};

export default Login;
