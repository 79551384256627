import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { GraphModal } from "./GraphModal";
import Login from "./Login";
import TicketTable from "./TicketTable";
import { toast } from "react-toastify";


const TicketPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pieData, setPieData] = useState({});
  const [priorityPieData, setPriorityPieData] = useState({});
  const [barData, setBarData] = useState({});

  const handleLogin = async () => {
    setLoading(true);
    // setError("");

    // Validate input
    if (!username || !password) {
      toast.error('Please enter a valid username and password.')
      setLoading(false);
      return;
    }

    const auth = Buffer.from(`${username}:${password}`).toString("base64");
    const url = new URL('https://jira.production.corporate.ladbrokes.cloud/rest/api/latest/search');
    Promise.race([
      fetch(url, {
        headers: {
          Authorization: `Basic ${auth}`,
          "Content-Type": "application/json",
        },
        params: {
          jql: `watcher = ${username}`,
          fields: "key,summary,created,duedate,assignee,creator,customfield_10400,status,updated,reporter,issuetype,priority",
        },
        method: "POST",
        // body: JSON.stringify({
        //   username,
        //   password,
        //   // jql: `watcher = ${username}`,
        //   // fields:
        //   //   "key,summary,created,duedate,assignee,creator,customfield_10400,status,updated,reporter,issuetype,priority",
        //   maxResults: 200,
        // }),
      }),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Timeout")), 30000)
      ),
    ])
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          throw new Error("Invalid username or password.");
        } else {
          throw new Error("Failed to fetch JIRA data.");
        }
      })
      .then((data) => {
        setTickets(data.issues);
        setIsLoggedIn(true);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message || "An error occurred while fetching JIRA data.")
      })
      .finally(() => setLoading(false));
  };

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    setSortConfig({ key, direction });
  };

  const compare = (a, b, key) => {
    if (key === "created" || key === "duedate" || key === "updated") {
      const dateA = new Date(a.fields[key]);
      const dateB = new Date(b.fields[key]);
      return sortConfig.direction === "ascending"
        ? dateA - dateB
        : dateB - dateA;
    } else {
      if (a.fields[key] < b.fields[key])
        return sortConfig.direction === "ascending" ? -1 : 1;
      if (a.fields[key] > b.fields[key])
        return sortConfig.direction === "ascending" ? 1 : -1;
    }
    const aValue = key === "priority" ? a.fields[key].name : a.fields[key];
    const bValue = key === "priority" ? b.fields[key].name : b.fields[key];

    if (aValue < bValue) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  };

  const filterTickets = (tickets) => {
    if (selectedStatus === "All") {
      return tickets;
    }
    return tickets.filter(
      (ticket) => ticket.fields.status.name === selectedStatus
    );
  };

  const sortedTickets = useMemo(() => {
    const filteredTickets = filterTickets(tickets);

    if (sortConfig.key) {
      return [...filteredTickets].sort((a, b) => compare(a, b, sortConfig.key));
    }
    return filteredTickets;
  }, [tickets, sortConfig, selectedStatus]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US");
  };

  const getDateFourWeeksAgo = () => {
    const date = new Date();
    date.setDate(date.getDate() - 28);
    return date;
  };

  const generateChartData = (tickets) => {
    const issueTypeCounts = {};
    const priorityCounts = {};
    const lastFourWeeksCounts = { open: 0, closed: 0 };
    const fourWeeksAgoDate = getDateFourWeeksAgo();

    tickets.forEach((ticket) => {
      if (!ticket.fields) return;

      // Count issue types
      if (ticket.fields.issuetype && ticket.fields.issuetype.name) {
        if (!issueTypeCounts[ticket.fields.issuetype.name]) {
          issueTypeCounts[ticket.fields.issuetype.name] = 0;
        }
        issueTypeCounts[ticket.fields.issuetype.name]++;
      }

      // Count priority types
      if (ticket.fields.priority && ticket.fields.priority.name) {
        if (!priorityCounts[ticket.fields.priority.name]) {
          priorityCounts[ticket.fields.priority.name] = 0;
        }
        priorityCounts[ticket.fields.priority.name]++;
      }

      // Count open and closed tickets in the last 4 weeks
      if (
        ticket.fields.updated &&
        ticket.fields.status &&
        ticket.fields.status.name
      ) {
        const updatedDate = new Date(ticket.fields.updated);
        if (updatedDate >= fourWeeksAgoDate) {
          if (ticket.fields.status.name === "Closed") {
            lastFourWeeksCounts.closed++;
          } else {
            lastFourWeeksCounts.open++;
          }
        }
      }
    });

    const pieData = {
      labels: Object.keys(issueTypeCounts),
      datasets: [
        {
          data: Object.values(issueTypeCounts),
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
      ],
    };

    const priorityPieData = {
      labels: Object.keys(priorityCounts),
      datasets: [
        {
          data: Object.values(priorityCounts),
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
      ],
    };

    const barData = {
      labels: ["Open", "Closed"],
      datasets: [
        {
          label: "Tickets",
          data: [lastFourWeeksCounts.open, lastFourWeeksCounts.closed],
          backgroundColor: [
            "rgba(75, 192, 192, 1.0)",
            "rgba(255, 99, 132, 1.0)",
          ],
          borderColor: ["white"],
          borderWidth: 2,
        },
      ],
    };

    return { pieData, priorityPieData, barData };
  };

  useEffect(() => {
    const { pieData, priorityPieData, barData } = generateChartData(tickets);
    setPieData(pieData);
    setPriorityPieData(priorityPieData);
    setBarData(barData);
  }, [tickets]);

  const getNeedleColor = (storyPoints) => {
    if (storyPoints < 2) {
      return "green";
    } else if (storyPoints >= 2 && storyPoints < 5) {
      return "yellow";
    } else {
      return "red";
    }
  };

  const NavFilters = () => {
    return (
      <Flex justifyContent={"space-between"}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          color="blue.500"
          onClick={onOpen}
        >
          View Graphs
        </MenuButton>

        <Box px="0.5"></Box>

        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          color="blue.500"
        >
          Filter by Status: {selectedStatus}
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => setSelectedStatus("All")}>All</MenuItem>
          <MenuItem onClick={() => setSelectedStatus("Open")}>Open</MenuItem>
          <MenuItem onClick={() => setSelectedStatus("Prioritised")}>
            Prioritised
          </MenuItem>
          <MenuItem onClick={() => setSelectedStatus("In Progress")}>
            In Progress
          </MenuItem>
          <MenuItem onClick={() => setSelectedStatus("Code Review")}>
            Code Review
          </MenuItem>
          <MenuItem onClick={() => setSelectedStatus("Ready To Deploy UAT")}>
            Ready To Deploy UAT
          </MenuItem>
          <MenuItem
            onClick={() => setSelectedStatus("User Acceptance Testing")}
          >
            User Acceptance Testing
          </MenuItem>
          <MenuItem onClick={() => setSelectedStatus("Done")}>Done</MenuItem>
        </MenuList>
      </Flex>
    );
  };

  return (
    <>
      <Box p={2}>
        <VStack minH="100vh" alignItems="center" p={0}>
          <Flex
            as="header"
            align="center"
            justify="space-between"
            h="75px"
            backgroundColor="blue.500"
            p={4}
            w="100%"
          >
            <Heading fontSize="2xl" fontWeight="bold" color="white">
              JIRA Watcher Tickets
            </Heading>
            <Menu>
              {isLoggedIn && (
                <>
                  <NavFilters />
                </>
              )}
            </Menu>
            <GraphModal
              isOpen={isOpen}
              onClose={onClose}
              barData={barData}
              pieData={pieData}
              priorityPieData={priorityPieData}
            />
          </Flex>
          {!isLoggedIn && (
            <Login
              setUsername={setUsername}
              setPassword={setPassword}
              handleLogin={handleLogin}
              username={username}
              password={password}
              loading={loading}
            />
          )}

          {/* {error && <Text color="red.500">{error}</Text>} */}

          {isLoggedIn && (
            <TicketTable
              formatDate={formatDate}
              getNeedleColor={getNeedleColor}
              sortData={sortData}
              username={username}
              sortedTickets={sortedTickets}
            />
          )}
        </VStack>
      </Box>
    </>
  );
}

export default TicketPage;
