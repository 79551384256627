// import { useState, useEffect, useMemo } from "react";
// import {
//   Box,
//   Grid,
//   Button,
//   FormControl,
//   FormLabel,
//   Heading,
//   Input,
//   Text,
//   Table,
//   Thead,
//   Tbody,
//   Tfoot,
//   Tr,
//   Th,
//   Td,
//   TableCaption,
//   Flex,
//   Menu,
//   MenuButton,
//   MenuList,
//   MenuItem,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalCloseButton,
//   ModalBody,
//   useDisclosure,
//   VStack,
// } from "@chakra-ui/react";
// import { ChevronDownIcon, TimeIcon } from "@chakra-ui/icons";
// import { Pie, Bar } from "react-chartjs-2";

// function App() {
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [tickets, setTickets] = useState([]);
//   const [selectedStatus, setSelectedStatus] = useState("All");
//   const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const [pieData, setPieData] = useState({});
//   const [priorityPieData, setPriorityPieData] = useState({});
//   const [barData, setBarData] = useState({});

//   const handleLogin = async () => {
//     setLoading(true);
//     setError("");

//     // Validate input
//     if (!username || !password) {
//       setError("Please enter a valid username and password.");
//       setLoading(false);
//       return;
//     }

//     const url = new URL("http://localhost:3002/api/jira-search");
//     Promise.race([
//       fetch(url, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//         method: "POST",
//         body: JSON.stringify({
//           username,
//           password,
//           jql: `watcher = ${username}`,
//           fields:
//             "key,summary,created,duedate,assignee,creator,customfield_10400,status,updated,reporter,issuetype,priority",
//           maxResults: 200,
//         }),
//       }),
//       new Promise((_, reject) =>
//         setTimeout(() => reject(new Error("Timeout")), 5000)
//       ),
//     ])
//       .then((response) => {
//         if (response.ok) {
//           return response.json();
//         } else if (response.status === 401) {
//           throw new Error("Invalid username or password.");
//         } else {
//           throw new Error("Failed to fetch JIRA data.");
//         }
//       })
//       .then((data) => {
//         setTickets(data.issues);
//         setIsLoggedIn(true);
//       })
//       .catch((err) => {
//         console.error(err);
//         setError(err.message || "An error occurred while fetching JIRA data.");
//       })
//       .finally(() => setLoading(false));
//   };

//   const sortData = (key) => {
//     let direction = "ascending";
//     if (sortConfig.key === key && sortConfig.direction === "ascending") {
//       direction = "descending";
//     }

//     setSortConfig({ key, direction });
//   };

//   const compare = (a, b, key) => {
//     if (key === "created" || key === "duedate" || key === "updated") {
//       const dateA = new Date(a.fields[key]);
//       const dateB = new Date(b.fields[key]);
//       return sortConfig.direction === "ascending"
//         ? dateA - dateB
//         : dateB - dateA;
//     } else {
//       if (a.fields[key] < b.fields[key])
//         return sortConfig.direction === "ascending" ? -1 : 1;
//       if (a.fields[key] > b.fields[key])
//         return sortConfig.direction === "ascending" ? 1 : -1;
//     }
//     const aValue = key === "priority" ? a.fields[key].name : a.fields[key];
//     const bValue = key === "priority" ? b.fields[key].name : b.fields[key];

//     if (aValue < bValue) {
//       return sortConfig.direction === "ascending" ? -1 : 1;
//     }
//     if (aValue > bValue) {
//       return sortConfig.direction === "ascending" ? 1 : -1;
//     }
//     return 0;
//   };

//   const filterTickets = (tickets) => {
//     if (selectedStatus === "All") {
//       return tickets;
//     }
//     return tickets.filter(
//       (ticket) => ticket.fields.status.name === selectedStatus
//     );
//   };

//   const sortedTickets = useMemo(() => {
//     const filteredTickets = filterTickets(tickets);

//     if (sortConfig.key) {
//       return [...filteredTickets].sort((a, b) => compare(a, b, sortConfig.key));
//     }
//     return filteredTickets;
//   }, [tickets, sortConfig, selectedStatus]);

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toLocaleDateString("en-US");
//   };

//   const getDateFourWeeksAgo = () => {
//     const date = new Date();
//     date.setDate(date.getDate() - 28);
//     return date;
//   };

//   const generateChartData = (tickets) => {
//     const issueTypeCounts = {};
//     const priorityCounts = {};
//     const lastFourWeeksCounts = { open: 0, closed: 0 };
//     const fourWeeksAgoDate = getDateFourWeeksAgo();

//     tickets.forEach((ticket) => {
//       if (!ticket.fields) return;

//       // Count issue types
//       if (ticket.fields.issuetype && ticket.fields.issuetype.name) {
//         if (!issueTypeCounts[ticket.fields.issuetype.name]) {
//           issueTypeCounts[ticket.fields.issuetype.name] = 0;
//         }
//         issueTypeCounts[ticket.fields.issuetype.name]++;
//       }

//       // Count priority types
//       if (ticket.fields.priority && ticket.fields.priority.name) {
//         if (!priorityCounts[ticket.fields.priority.name]) {
//           priorityCounts[ticket.fields.priority.name] = 0;
//         }
//         priorityCounts[ticket.fields.priority.name]++;
//       }

//       // Count open and closed tickets in the last 4 weeks
//       if (
//         ticket.fields.updated &&
//         ticket.fields.status &&
//         ticket.fields.status.name
//       ) {
//         const updatedDate = new Date(ticket.fields.updated);
//         if (updatedDate >= fourWeeksAgoDate) {
//           if (ticket.fields.status.name === "Closed") {
//             lastFourWeeksCounts.closed++;
//           } else {
//             lastFourWeeksCounts.open++;
//           }
//         }
//       }
//     });

//     const pieData = {
//       labels: Object.keys(issueTypeCounts),
//       datasets: [
//         {
//           data: Object.values(issueTypeCounts),
//           backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
//         },
//       ],
//     };

//     const priorityPieData = {
//       labels: Object.keys(priorityCounts),
//       datasets: [
//         {
//           data: Object.values(priorityCounts),
//           backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
//         },
//       ],
//     };

//     const barData = {
//       labels: ["Open", "Closed"],
//       datasets: [
//         {
//           label: "Tickets",
//           data: [lastFourWeeksCounts.open, lastFourWeeksCounts.closed],
//           backgroundColor: [
//             "rgba(75, 192, 192, 1.0)",
//             "rgba(255, 99, 132, 1.0)",
//           ],
//           borderColor: ["white"],
//           borderWidth: 2,
//         },
//       ],
//     };

//     return { pieData, priorityPieData, barData };
//   };

//   useEffect(() => {
//     const { pieData, priorityPieData, barData } = generateChartData(tickets);
//     setPieData(pieData);
//     setPriorityPieData(priorityPieData);
//     setBarData(barData);
//   }, [tickets]);

//   const getNeedleColor = (storyPoints) => {
//     if (storyPoints < 2) {
//       return "green";
//     } else if (storyPoints >= 2 && storyPoints < 5) {
//       return "yellow";
//     } else {
//       return "red";
//     }
//   };

//   return (
//     <>
//       <Box p={2}>
//         <VStack minH="100vh" alignItems="center" p={0}>
//           <Flex
//             as="header"
//             align="center"
//             justify="space-between"
//             h="75px"
//             backgroundColor="blue.500"
//             p={4}
//             w="100%"
//           >
//             <Text fontSize="2xl" fontWeight="bold" color="white">
//               JIRA Watcher Tickets
//             </Text>
//             <Menu>
//               {isLoggedIn && (
//                 <>
//                   <Flex justifyContent={"space-between"}>
//                     <MenuButton
//                       as={Button}
//                       rightIcon={<ChevronDownIcon />}
//                       color="blue.500"
//                       onClick={onOpen}
//                     >
//                       View Graphs
//                     </MenuButton>

//                     <Box px="0.5"></Box>

//                     <MenuButton
//                       as={Button}
//                       rightIcon={<ChevronDownIcon />}
//                       color="blue.500"
//                     >
//                       Filter by Status: {selectedStatus}
//                     </MenuButton>
//                     <MenuList>
//                       <MenuItem onClick={() => setSelectedStatus("All")}>
//                         All
//                       </MenuItem>
//                       <MenuItem onClick={() => setSelectedStatus("Open")}>
//                         Open
//                       </MenuItem>
//                       <MenuItem
//                         onClick={() => setSelectedStatus("Prioritised")}
//                       >
//                         Prioritised
//                       </MenuItem>
//                       <MenuItem
//                         onClick={() => setSelectedStatus("In Progress")}
//                       >
//                         In Progress
//                       </MenuItem>
//                       <MenuItem
//                         onClick={() => setSelectedStatus("Code Review")}
//                       >
//                         Code Review
//                       </MenuItem>
//                       <MenuItem
//                         onClick={() => setSelectedStatus("Ready To Deploy UAT")}
//                       >
//                         Ready To Deploy UAT
//                       </MenuItem>
//                       <MenuItem
//                         onClick={() =>
//                           setSelectedStatus("User Acceptance Testing")
//                         }
//                       >
//                         User Acceptance Testing
//                       </MenuItem>
//                       <MenuItem onClick={() => setSelectedStatus("Done")}>
//                         Done
//                       </MenuItem>
//                     </MenuList>
//                   </Flex>
//                 </>
//               )}
//             </Menu>
//             <Modal isOpen={isOpen} onClose={onClose} size="full">
//               <ModalOverlay />
//               <ModalContent>
//                 <ModalHeader>Graphs</ModalHeader>
//                 <ModalCloseButton />
//                 <ModalBody>
//                   <Grid templateColumns="repeat(3, 1fr)" gap={6}>
//                     <Box
//                       w="100%"
//                       h="550px"
//                       borderWidth="1px"
//                       borderColor="gray.200"
//                       borderRadius="md"
//                       overflow="hidden"
//                     >
//                       <Box
//                         p={4}
//                         borderBottomWidth="1px"
//                         borderColor="gray.200"
//                         bg="white"
//                         mb={4}
//                       >
//                         <Text fontSize="xl" fontWeight="semibold">
//                           Open Tickets by Issue Type
//                         </Text>
//                       </Box>
//                       <Box p={4}>
//                         <Pie data={pieData} />
//                       </Box>
//                     </Box>

//                     <Box
//                       w="100%"
//                       h="550px"
//                       borderWidth="1px"
//                       borderColor="gray.200"
//                       borderRadius="md"
//                       overflow="hidden"
//                     >
//                       <Box
//                         p={4}
//                         borderBottomWidth="1px"
//                         borderColor="gray.200"
//                         bg="white"
//                         mb={4}
//                       >
//                         <Text fontSize="xl" fontWeight="semibold">
//                           Open Tickets by Priority
//                         </Text>
//                       </Box>
//                       <Box p={4}>
//                         <Pie data={priorityPieData} />
//                       </Box>
//                     </Box>

//                     <Box
//                       w="100%"
//                       h="550px"
//                       borderWidth="1px"
//                       borderColor="gray.200"
//                       borderRadius="md"
//                       overflow="hidden"
//                     >
//                       <Box
//                         p={4}
//                         borderBottomWidth="1px"
//                         borderColor="gray.200"
//                         bg="white"
//                         mb={4}
//                       >
//                         <Text fontSize="xl" fontWeight="semibold">
//                           Open Tickets by Status
//                         </Text>
//                       </Box>
//                       <Box p={4}>
//                         <Bar data={barData} />
//                       </Box>
//                     </Box>
//                   </Grid>
//                 </ModalBody>
//               </ModalContent>
//             </Modal>
//           </Flex>
//           {!isLoggedIn && (
//             <VStack
//               as="form"
//               onSubmit={(e) => {
//                 e.preventDefault();
//                 handleLogin();
//               }}
//               spacing={4}
//               w="full"
//               maxWidth="600px"
//             >
//               <FormControl id="username" isRequired>
//                 <FormLabel>Username</FormLabel>
//                 <Input
//                   value={username}
//                   onChange={(e) => setUsername(e.target.value)}
//                 />
//               </FormControl>
//               <FormControl id="password" isRequired>
//                 <FormLabel>Password</FormLabel>
//                 <Input
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                   type="password"
//                 />
//               </FormControl>
//               <Button isLoading={loading} type="submit" colorScheme="blue">
//                 Login
//               </Button>
//             </VStack>
//           )}

//           {error && <Text color="red.500">{error}</Text>}

//           {isLoggedIn && (
//             <Box maxWidth="100%" overflowX="auto">
//               <Grid
//                 templateColumns={{
//                   base: "repeat(1, 1fr)",
//                   md: "repeat(14, 1fr)",
//                 }}
//                 gap={4}
//               >
//                 <Table
//                   variant="striped"
//                   maxWidth="100%"
//                   gridColumn={{ md: "span 12" }}
//                 >
//                   <TableCaption fontSize="xs">
//                     Tickets watched by {username}
//                   </TableCaption>
//                   <Thead>
//                     <Tr>
//                       <Th fontSize="xs">Ticket Number</Th>
//                       <Th fontSize="xs">Summary</Th>
//                       <Th onClick={() => sortData("created")} fontSize="xs">
//                         Created
//                       </Th>
//                       <Th onClick={() => sortData("created")} fontSize="xs">
//                         Due
//                       </Th>
//                       <Th fontSize="xs">Assigned to</Th>
//                       <Th fontSize="xs">Created by</Th>
//                       <Th
//                         display={{ base: "none", md: "table-cell" }}
//                         fontSize="xs"
//                       >
//                         Avatar
//                       </Th>
//                       <Th fontSize="xs">Story Points</Th>
//                       <Th fontSize="xs">Status</Th>
//                       <Th onClick={() => sortData("updated")} fontSize="xs">
//                         Last Updated
//                       </Th>
//                       <Th fontSize="xs">Reporter</Th>
//                       <Th fontSize="xs">Issue Type</Th>
//                       <Th onClick={() => sortData("priority")} fontSize="xs">
//                         Priority
//                       </Th>
//                     </Tr>
//                   </Thead>
//                   <Tbody>
//                     {sortedTickets.map((ticket) => (
//                       <Tr key={`${ticket.id}`}>
//                         <Td fontSize="xs">{ticket.key}</Td>
//                         <Td fontSize="xs">{ticket.fields.summary}</Td>
//                         <Td fontSize="xs">
//                           {formatDate(ticket.fields.created)}
//                         </Td>
//                         <Td fontSize="xs">
//                           {formatDate(ticket.fields.duedate)}
//                         </Td>
//                         <Td fontSize="xs">
//                           {ticket.fields.assignee
//                             ? ticket.fields.assignee.displayName
//                             : "Unassigned"}
//                         </Td>
//                         <Td fontSize="xs">
//                           {ticket.fields.creator.displayName}
//                         </Td>
//                         <Td
//                           display={{ base: "none", md: "table-cell" }}
//                           fontSize="xs"
//                         >
//                           <Box
//                             as="img"
//                             src={ticket.fields.creator.avatarUrls["48x48"]}
//                             alt="Creator Avatar"
//                             width={8}
//                             height={8}
//                           />
//                         </Td>
//                         <Td
//                           fontSize="xs"
//                           title={
//                             ticket.fields.customfield_10400 !== null
//                               ? ticket.fields.customfield_10400
//                               : "N/A"
//                           }
//                           _hover={{
//                             textDecoration: "underline",
//                             pointer: "cursor",
//                           }}
//                         >
//                           {ticket.fields.customfield_10400 !== null ? (
//                             <TimeIcon
//                               boxSize={6}
//                               color={getNeedleColor(
//                                 ticket.fields.customfield_10400
//                               )}
//                             />
//                           ) : (
//                             "N/A"
//                           )}
//                         </Td>
//                         <Td fontSize="xs">{ticket.fields.status.name}</Td>
//                         <Td fontSize="xs">
//                           {formatDate(ticket.fields.updated)}
//                         </Td>
//                         <Td fontSize="xs">
//                           {ticket.fields.reporter.displayName}
//                         </Td>
//                         <Td fontSize="xs">{ticket.fields.issuetype.name}</Td>
//                         <Td fontSize="xs">{ticket.fields.priority.name}</Td>
//                       </Tr>
//                     ))}
//                   </Tbody>
//                 </Table>
//               </Grid>
//             </Box>
//           )}
//         </VStack>
//       </Box>
//     </>
//   );
// }

// export default App;

import React, { useState, useEffect } from "react";
import { Link, Routes, Route, BrowserRouter } from "react-router-dom";
import TicketPage from "./components/TicketPage";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<TicketPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;