import React from "react";
import { Bar, Pie } from "react-chartjs-2";
import {
    Box,
    Grid,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";

export const GraphModal = ({ barData, isOpen, pieData, onClose, priorityPieData }) => {


  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Graphs</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="repeat(3, 1fr)" gap={6}>
            <Box
              w="100%"
              h="550px"
              borderWidth="1px"
              borderColor="gray.200"
              borderRadius="md"
              overflow="hidden"
            >
              <Box
                p={4}
                borderBottomWidth="1px"
                borderColor="gray.200"
                bg="white"
                mb={4}
              >
                <Text fontSize="xl" fontWeight="semibold">
                  Open Tickets by Issue Type
                </Text>
              </Box>
              <Box p={4}>
                <Pie data={pieData} />
              </Box>
            </Box>

            <Box
              w="100%"
              h="550px"
              borderWidth="1px"
              borderColor="gray.200"
              borderRadius="md"
              overflow="hidden"
            >
              <Box
                p={4}
                borderBottomWidth="1px"
                borderColor="gray.200"
                bg="white"
                mb={4}
              >
                <Text fontSize="xl" fontWeight="semibold">
                  Open Tickets by Priority
                </Text>
              </Box>
              <Box p={4}>
                <Pie data={priorityPieData} />
              </Box>
            </Box>

            <Box
              w="100%"
              h="550px"
              borderWidth="1px"
              borderColor="gray.200"
              borderRadius="md"
              overflow="hidden"
            >
              <Box
                p={4}
                borderBottomWidth="1px"
                borderColor="gray.200"
                bg="white"
                mb={4}
              >
                <Text fontSize="xl" fontWeight="semibold">
                  Open Tickets by Status
                </Text>
              </Box>
              <Box p={4}>
                <Bar data={barData} />
              </Box>
            </Box>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
