import * as React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import App from "./App";
import { Buffer } from "buffer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

window.Buffer = window.Buffer || Buffer;

const theme = extendTheme({
  colors: {
    atlassianBlue: {
      500: "#0052CC",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
      <ToastContainer />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
