import React from "react";
import {
    Box,
    Grid,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
} from "@chakra-ui/react";
import { TimeIcon } from "@chakra-ui/icons";

const TicketTable = ({ username, sortedTickets, sortData, getNeedleColor, formatDate }) => {
  return (
    <Box maxWidth="100%" overflowX="auto">
        <Table variant="striped" maxWidth="100%" gridColumn={{ md: "span 12" }}>
          <TableCaption fontSize="xs">
            Tickets watched by {username}
          </TableCaption>
          <Thead>
            <Tr>
              <Th fontSize="xs">Ticket Number</Th>
              <Th fontSize="xs">Summary</Th>
              <Th onClick={() => sortData("created")} fontSize="xs">
                Created
              </Th>
              <Th onClick={() => sortData("created")} fontSize="xs">
                Due
              </Th>
              <Th fontSize="xs">Assigned to</Th>
              <Th fontSize="xs">Created by</Th>
              <Th display={{ base: "none", md: "table-cell" }} fontSize="xs">
                Avatar
              </Th>
              <Th fontSize="xs">Story Points</Th>
              <Th fontSize="xs">Status</Th>
              <Th onClick={() => sortData("updated")} fontSize="xs">
                Last Updated
              </Th>
              <Th fontSize="xs">Reporter</Th>
              <Th fontSize="xs">Issue Type</Th>
              <Th onClick={() => sortData("priority")} fontSize="xs">
                Priority
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedTickets.map((ticket) => (
              <Tr key={`${ticket.id}`}>
                <Td fontSize="xs">{ticket.key}</Td>
                <Td fontSize="xs">{ticket.fields.summary}</Td>
                <Td fontSize="xs">{formatDate(ticket.fields.created)}</Td>
                <Td fontSize="xs">{formatDate(ticket.fields.duedate)}</Td>
                <Td fontSize="xs">
                  {ticket.fields.assignee
                    ? ticket.fields.assignee.displayName
                    : "Unassigned"}
                </Td>
                <Td fontSize="xs">{ticket.fields.creator.displayName}</Td>
                <Td display={{ base: "none", md: "table-cell" }} fontSize="xs">
                  <Box
                    as="img"
                    src={ticket.fields.creator.avatarUrls["48x48"]}
                    alt="Creator Avatar"
                    width={8}
                    height={8}
                  />
                </Td>
                <Td
                  fontSize="xs"
                  title={
                    ticket.fields.customfield_10400 !== null
                      ? ticket.fields.customfield_10400
                      : "N/A"
                  }
                  _hover={{
                    textDecoration: "underline",
                    pointer: "cursor",
                  }}
                >
                  {ticket.fields.customfield_10400 !== null ? (
                    <TimeIcon
                      boxSize={6}
                      color={getNeedleColor(ticket.fields.customfield_10400)}
                    />
                  ) : (
                    "N/A"
                  )}
                </Td>
                <Td fontSize="xs">{ticket.fields.status.name}</Td>
                <Td fontSize="xs">{formatDate(ticket.fields.updated)}</Td>
                <Td fontSize="xs">{ticket.fields.reporter.displayName}</Td>
                <Td fontSize="xs">{ticket.fields.issuetype.name}</Td>
                <Td fontSize="xs">{ticket.fields.priority.name}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
    </Box>
  );
};

export default TicketTable;
